import * as React from 'react'

const { useState, Fragment, isValidElement } = React

/*
The purpose of this hook is to keep track if the last click was made inside a specific component, or outside
So that an inner popup can be opened and closed accordingly. 
*/
const useFormHelper = ({ schema, initialState, ErrorWrapper, NoErrorWrapper }) => {
  const [formValues, setFormValues] = useState(initialState)
  const [errors, setErrors] = useState({})
  const [globalErrors, setGlobalErrors] = useState([])
  const [formSubmitted, setFormSubmitted] = useState(false)

  const setFormValue = (field, val) => {
    var newValues = formValues
    if (typeof field === 'object') newValues = { ...formValues, ...field }
    else if (typeof field === 'string') {
      newValues = { ...formValues, [field]: val }
      field = { [field]: val }
    } else return

    setFormValues(newValues)
    Object.keys(field).forEach(key => {
      setErrors({ ...errors, [key]: { ...errors[key], dirty: true } })
      schema
        .validateAt(key, newValues)
        .then(() => {
          setErrors({ ...errors, [key]: { ...errors[key], dirty: true, message: null } })
        })
        .catch(err => {
          setErrors({ ...errors, [key]: { ...errors[key], dirty: true, message: err.message } })
        })
    })
  }

  const hasErrors = () => !!Object.keys(errors).find(key => !!errors[key].message) || globalErrors.length

  const showError = field => {
    const NoError = isValidElement(NoErrorWrapper) ? <NoErrorWrapper field={field} /> : ({ field }) => <Fragment />

    return (formSubmitted || errors[field]?.dirty) && errors[field]?.message ? (
      <ErrorWrapper field={field}>{errors[field].message}</ErrorWrapper>
    ) : (
      <NoError />
    )
  }

  const parseErrors = ex => {
    var errs = { ...errors }
    var globalErrs = []
    Object.keys(errs).forEach(key => delete errs[key]?.message)
    ex.inner.forEach(e => {
      if (e.path.length === 0) globalErrs.push(e.message)
      else errs = { ...errs, [e.path]: { ...errs[e.path], message: e.message } }
    })
    setErrors({ ...errs })
    setGlobalErrors(globalErrs)
  }

  const handleSubmit = async callback => {
    try {
      setFormSubmitted(true)
      await schema.validate(formValues, { abortEarly: false })
      callback()
    } catch (ex) {
      parseErrors(ex)
    }
  }

  return { formValues, setFormValue, handleSubmit, showError, globalErrors, hasErrors }
}

export default useFormHelper
