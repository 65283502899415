import * as React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import './App.scss'
import env from 'react-dotenv'
import moment from 'moment'
import toaster from 'react-hot-toast'
import * as yup from 'yup'
import useFormHelper from '../../hooks/useFormHelper.js'

import Header from '../../components/Header/Header'

const { useState, useEffect } = React

const schema = yup.object().shape({
  url: yup.string().required('Missing url address')
})

const App = () => {
  const links = useStoreState(state => state.link.all || [])
  const getLinks = useStoreActions(actions => actions.link.getLinks)
  const createLink = useStoreActions(actions => actions.link.createLink)
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(null)

  const { formValues, setFormValue, handleSubmit, showError, hasErrors } = useFormHelper({
    schema: schema,
    initialState: { url: '' },
    ErrorWrapper: ({ children }) => <p className="small error">{children}</p>
  })

  useEffect(() => {
    setLoading(true)
    getLinks({ options: { completed: () => setLoading(false) } })
  }, [reload])

  return (
    <section className="app-page">
      <Header />
      <div className="body-content">
        <div className="links-table">
          <div className="sticky-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Link</th>
                  <th className="expanding">Url</th>
                  <th>Visits</th>
                </tr>
              </thead>
              <tbody>
                {!links.length && (
                  <tr>
                    <td colSpan={4}>No results</td>
                  </tr>
                )}
                {links.map((link, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{moment(link.created).format('DD/MM/YYYY')}</td>
                      <td
                        className="clickable"
                        onClick={e => {
                          navigator.clipboard.writeText(new URL(e.target.innerText, env.SERVER_URL).href)
                          toaster.success('Url copied successfully to clipboard')
                        }}
                      >
                        {link.short}
                      </td>
                      <td
                        className="clickable"
                        onClick={e => {
                          navigator.clipboard.writeText(new URL(e.target.innerText, env.SERVER_URL).href)
                          toaster.success('Url copied successfully to clipboard')
                        }}
                      >
                        {link.url}
                      </td>
                      <td>{link.visits || 0}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="new-link-creator">
          <form
            className={`normal-form no-gap ${loading ? 'submitting' : ''}`}
            action=""
            onSubmit={e => {
              e.preventDefault()
              handleSubmit(() => {
                setLoading(true)
                createLink({
                  ...formValues,
                  options: {
                    completed: short => {
                      setLoading(false)
                      toaster.success(`Success, your new link is: ${new URL(short, env.SERVER_URL).href}`, {
                        duration: 5000
                      })
                      setReload(Math.random())
                    }
                  }
                })
              })
            }}
          >
            <div className="row inline">
              <input
                placeholder="Place your long url here"
                type="text"
                autoComplete="off"
                onChange={e => setFormValue('url', e.target.value)}
              />
              <button className="action">Shorten</button>
            </div>
            <div>{showError('url')}</div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default App
