import { action, thunk } from 'easy-peasy'
import { api, Auth } from '../api.js'

const LinkModel = {
  all: null,

  getLinks: thunk(async (actions, payload, helpers) => {
    const { options } = payload

    let headers = Auth.getRequestHeaders()

    let response = await api.post('/link/all', {}, { headers })

    if (!response.data.error) actions.setLinks(response.data.links)

    helpers.getStoreActions().common.processResponse({ response, options })
  }),

  setLinks: action((state, payload) => {
    state.all = payload
  }),

  createLink: thunk(async (actions, payload, helpers) => {
    const { url, options } = payload

    let headers = Auth.getRequestHeaders()

    let response = await api.post('/link/create', { url }, { headers })

    if (response.data.error) {
      helpers.getStoreActions().common.processResponse({ response, options })
    } else {
      options.completed(response.data.short)
    }
  })
}

export default LinkModel
