import * as React from 'react'
import { useStoreActions } from 'easy-peasy'
import * as yup from 'yup'
import useFormHelper from '../../hooks/useFormHelper.js'
import { useNavigate } from 'react-router-dom'

import './Login.scss'

import KeyIcon from '../../images/key.svg'
import EmailIcon from '../../images/email.svg'
import Header from '../../components/Header/Header.js'

const schema = yup.object().shape({
  email: yup.string().email().required('Missing or invalid email address'),
  password: yup.string().required('Missing password'),
  remember: yup.boolean()
})

const { useState } = React

const RESET_FORM = { email: '', password: '', remember: true }

const Login = () => {
  const doLogin = useStoreActions(actions => actions.user.doLogin)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const { formValues, setFormValue, handleSubmit, showError, hasErrors } = useFormHelper({
    schema: schema,
    initialState: RESET_FORM,
    ErrorWrapper: ({ children }) => <p className="small error">{children}</p>
  })

  return (
    <section className="login-page">
      <Header />
      <div className="centered-box">
        <form
          action=""
          className={`normal-form log-in ${loading ? 'submitting' : ''}`}
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault()
            handleSubmit(() => {
              setLoading(true)
              doLogin({
                ...formValues,
                options: {
                  completed: err => {
                    setLoading(false)
                    if (!err) {
                      navigate('/app')
                    }
                  }
                }
              })
            })
          }}
        >
          <h2>Log in to 1W.VC</h2>
          <div className="row">
            <label htmlFor="email">Email:</label>
            <span className="input-with-icon">
              <img src={EmailIcon} />
              <input
                placeholder="Email"
                type="email"
                autoComplete="off"
                onChange={e => setFormValue('email', e.target.value)}
              />
            </span>
            {showError('email')}
          </div>
          <div className="row">
            <label htmlFor="password">Password:</label>
            <span className="input-with-icon">
              <img src={KeyIcon} />
              <input
                placeholder="Password"
                type="password"
                autoComplete="off"
                onChange={e => setFormValue('password', e.target.value)}
              />
            </span>
            {showError('password')}
          </div>
          <div className="row">
            <span className="checkbox-wrapper">
              <input type="checkbox" name="remember" id="remember" />
              <label htmlFor="remember">Remember me</label>
            </span>
          </div>
          <div className="row">
            <button className="action">Log in</button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Login
