import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'

const { Fragment, useEffect } = React

const SecureRoutes = () => {
  const isLoggedIn = useStoreState(state => state.user.isLoggedIn)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    console.log(location.pathname)
    if (!isLoggedIn && location.pathname !== '/login') {
      navigate('/login')
    } else if (isLoggedIn && ['/', '/login'].includes(location.pathname)) {
      navigate('/app')
    }
  }, [isLoggedIn, location.pathname])

  return <Fragment />
}

export default SecureRoutes
