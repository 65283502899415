import { thunk } from 'easy-peasy'
import toaster from 'react-hot-toast'

const CommonModel = {
  //must be a thunk, otherwise creating an action that triggers access to store while dispatching will fail.
  processResponse: thunk(async (actions, payload, helpers) => {
    var { response, options } = payload
    response = response ? response.data : null

    if (response?.error) {
      //TODO: Manual closing, toast(t => (<span>Custom <button onClick={() => toast.dismiss(t.id)}>Dismiss</button></span> ), { icon: <Icon /> })
      let duration = 3000
      toaster.error(response.message, { duration, className: 'toaster' })
    } else if (options?.showSuccessMessage) {
      toaster.success(response.message)
    }

    if (options && options.completed) {
      options.completed(response ? response.error : null, response)
    }
  })
}

export default CommonModel
