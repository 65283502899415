import { action, thunk, computed } from 'easy-peasy'
import { api, Auth } from '../api.js'

const UserModel = {
  random: Math.random(),

  isLoggedIn: computed(state => {
    let user = Auth.getAuthentication()
    return !!user?.token
  }),

  doLogin: thunk(async (actions, payload, helpers) => {
    const { email, password, remember, options } = payload

    let response = await api.post('/user/login', {
      email,
      password,
      remember
    })

    if (!response.data.error) {
      actions.setAuthentication(response.data.user)
    } else {
      actions.doLogout({})
    }

    helpers.getStoreActions().common.processResponse({ response, options })
  }),
  /**
   * Fill store and local storage
   */
  setAuthentication: action((state, payload) => {
    //serialize it to local storage
    Auth.setAuthentication(payload)
    state.random = Math.random() //revoking the calculated value of isLoggedIn
  }),
  /**
   * Clear store and local storage
   */
  doLogout: action(state => {
    Auth.clearAuthentication()
    state.random = Math.random() //revoking the calculated value of isLoggedIn
  })
}

export default UserModel
