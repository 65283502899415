import * as React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import pgk from '../../../package.json'

import './Header.scss'

import Logo from '../../images/logo.svg'

const Header = () => {
  const isLoggedIn = useStoreState(state => state.user.isLoggedIn)
  const doLogout = useStoreActions(actions => actions.user.doLogout)

  return (
    <div className="site-header">
      <div className="logo">
        <img src={Logo} className="logo-image" />
        <div className="brand">1W.VC</div>
        <div className="slogen">The link shortner</div>
      </div>
      <div className="small version">v. {pgk.version}</div>
      {isLoggedIn && (
        <div
          className="login"
          onClick={() => {
            doLogout({})
          }}
        >
          Log out
        </div>
      )}
    </div>
  )
}

export default Header
