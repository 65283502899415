import axios from 'axios'
import toaster from 'react-hot-toast'
import env from 'react-dotenv'
const AUTH_TOKEN = 'X-Auth-Token'

export const api = axios.create({
  baseURL: new URL('/api/', env.SERVER_URL).href
})

api.interceptors.response.use(
  response => response,
  err => {
    if (err?.response?.status !== 200 && window.location.href.search(/\/login/i) === -1) {
      Auth.clearAuthentication()
      window.location.href = '/#/login'
    } else if (err?.response?.status !== 200) {
      toaster.error(err.message || 'There was a server error, please try again', {
        duration: 3000,
        className: 'toaster'
      })
    }

    return Promise.reject(err)
  }
)

export const Auth = {
  getRequestHeaders() {
    let user = this.getAuthentication()
    var result = {}

    if (!!user?.token?.length) {
      result[AUTH_TOKEN] = user.token
    }

    return result
  },

  clearAuthentication() {
    localStorage.removeItem(AUTH_TOKEN)
  },

  isAuthenticated() {
    let user = this.getAuthentication()
    return user && user.token.length > 0
  },

  getAuthentication() {
    let user = localStorage.getItem(AUTH_TOKEN)
    return user ? JSON.parse(user) : null
  },

  setAuthentication(data) {
    data = data || {}
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(data))
  }
}
