import * as React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'

import App from './pages/app/App.js'
import P404 from './pages/404.js'
import Login from './pages/login/Login.js'
import SecureRoutes from './secureroutes.js'

const ManageRoutes = () => {
  return (
    <HashRouter>
      <SecureRoutes />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/app" element={<App />} errorElement={<P404 />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </HashRouter>
  )
}

export default ManageRoutes
