import React from 'react'
import ReactDOM from 'react-dom/client'
import { StoreProvider } from 'easy-peasy'
import { Toaster } from 'react-hot-toast'

import store from './stores/index.js'
import ManageRoutes from './manageroutes.js'

import './index.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <Toaster />
      <ManageRoutes />
    </StoreProvider>
  </React.StrictMode>
)
