import { createStore } from 'easy-peasy'
import UserModel from './user.js'
import LinkModel from './link.js'
import CommonModel from './common.js'

const store = createStore({
  common: CommonModel,
  link: LinkModel,
  user: UserModel
})

export default store
